import * as React from 'react';

const useOutsideClick = (
  ref: React.MutableRefObject<any>,
  callback: () => void,
) => {
  const [active, setActive] = React.useState(false);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    if (active) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [active, ref, callback]);

  return [setActive];
};

export default useOutsideClick;
