const WORD_BOUNDARY = 300;

const getExcerpt = (content: string, searchTerm: string | undefined) => {
  let excerpt = '';

  // Remove HTML-Tags like <a>, <div>, etc.
  excerpt = content.replace(/(<([^>]+)>)/gi, '');

  // Remove Visual Composer Tags like [vc_row] etc.
  excerpt = excerpt.replace(/(\[([^\]]+)\])/gi, '');

  if (searchTerm) {
    const searchTermRegex = new RegExp(searchTerm, 'gi');
    const searchTermIndex = excerpt.search(searchTermRegex);
    const searchTermLength = searchTerm.length;
    const excerptLength = excerpt.length;

    let startIndex = searchTermIndex - WORD_BOUNDARY;
    let endIndex = searchTermIndex + searchTermLength + WORD_BOUNDARY;

    if (startIndex < WORD_BOUNDARY) startIndex = 0;
    if (endIndex > excerptLength) endIndex = excerptLength;

    // Get original string to surround with <span>
    const searchTermInExcerpt = excerpt.slice(
      searchTermIndex,
      searchTermIndex + searchTermLength,
    );

    excerpt = excerpt.slice(startIndex, endIndex);

    // Add Dots to beginning of string when not at beginning of excerpt
    if (startIndex > 0) {
      excerpt = '...' + excerpt;
    }

    // Add Dots to end of string when not at end of excerpt
    if (endIndex < excerptLength) {
      excerpt = excerpt + '...';
    }

    excerpt = excerpt.replace(
      searchTermRegex,
      `<span>${searchTermInExcerpt}</span>`,
    );
  } else {
    excerpt = excerpt.slice(0, WORD_BOUNDARY);
  }

  return excerpt;
};

export default getExcerpt;
