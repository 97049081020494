import React from 'react';

// Components
import DownloadButton from '../DownloadButton/DownloadButton';

// Other
import { MediaItem } from '../../types/MediaItems.types';
import './MediaItem.scss';
import UserContext from '../../context/UserContext';
import MediaItemLink from '../MediaItemLink/MediaItemLink';

interface IMediaItem {
  item: MediaItem;
  showHeadline?: boolean;
  sizeClasses?: string;
}

const MediaItemComponent: React.FC<IMediaItem> = props => {
  const {
    item,
    showHeadline = true,
    sizeClasses = 'col-12 col-sm-6 col-md-3',
  } = props;

  if (item.mediaLibraryFile.itemType === 'linked') {
    return <MediaItemLink item={item} />;
  }

  const sources = item.mediaLibraryFile.fileSources;
  const previewItem = {
    src:
      item.featuredImage?.node.srcSet ||
      sources[0].src.srcSet ||
      sources[0].src.guid,
    title: sources[0].src.title,
    width:
      item.featuredImage?.node.mediaDetails.width || sources[0].src.mediaDetails
        ? sources[0].src.mediaDetails.width
        : 150,
    height:
      item.featuredImage?.node.mediaDetails.height ||
      sources[0].src.mediaDetails
        ? sources[0].src.mediaDetails.height
        : 150,
  };

  return (
    <UserContext.Consumer>
      {user => (
        <div className={`media-item ${sizeClasses}`}>
          <img
            srcSet={previewItem.src}
            alt={previewItem.title}
            width={previewItem.width}
            height={previewItem.height}
          />
          {showHeadline && <p className="media-item__title">{item.title}</p>}
          <DownloadButton
            user={user}
            sources={sources}
            sensibleButton={item.mediaLibraryFile.itemType === 'public'}
          >
            Download
          </DownloadButton>
        </div>
      )}
    </UserContext.Consumer>
  );
};

export default MediaItemComponent;
