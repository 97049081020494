import * as React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import LayoutContainer from '../LayoutContainer/LayoutContainer';

import './Page.scss';

/**
 * GraphQL page query that takes a page slug as a uri
 * Returns the title and content of the page
 */
const PAGE_QUERY = gql`
  query PageQuery($uri: String!) {
    pageBy(uri: $uri) {
      databaseId
      title
      contentMapped
    }
  }
`;

interface PageProps {
  title: string;
  contentMapped: string;
  pageId: number;
}

const Page: React.FunctionComponent = () => {
  const [page, setPage] = React.useState<PageProps | null>();
  const { slug, parentslug }: any = useParams();
  const history = useHistory();

  const combinedSlug = parentslug ? `${parentslug}/${slug}` : slug;

  const handleComplete = (data: any) => {
    if (data) {
      if (!data.pageBy) {
        alert(
          'Not authorized to access this page. You will be redirected to Home.',
        );
        history.push('/');
        return false;
      }
      const { databaseId: pageId, title, contentMapped } = data.pageBy;

      setPage({
        pageId,
        title,
        contentMapped,
      });
    }
  };

  const { loading, error } = useQuery(PAGE_QUERY, {
    variables: { uri: combinedSlug },
    onCompleted: handleComplete,
  });

  if (error) {
    console.log('Page Error!', error);
  }

  return (
    <LayoutContainer>
      <div className="page">
        {loading && <p>Loading...</p>}
        {page && !loading && (
          <>
            <h1>{page.title}</h1>
            <div className="page__content">
              <div dangerouslySetInnerHTML={{ __html: page.contentMapped }} />
            </div>
          </>
        )}
      </div>
    </LayoutContainer>
  );
};

export default Page;
