import React from 'react';
import { createRoot } from 'react-dom/client';

import {
  ApolloClient,
  ApolloProvider,
  ApolloLink,
  InMemoryCache,
  from,
  HttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { BrowserRouter, useLocation } from 'react-router-dom';

// Components
import App from './components/App';

// Misc.
import Config from './config';
import { AUTH_TOKEN } from './constants';
import './styles/style.scss';

const authLink = new ApolloLink((operation, forward) => {
  const authToken = localStorage.getItem(AUTH_TOKEN);

  operation.setContext(({ headers = {} }: any) => ({
    headers: {
      ...headers,
      authorization: authToken ? `Bearer ${authToken}` : '',
    },
  }));

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    if (graphQLErrors[0].message === 'NO_ACCESS_ALLOWED') {
      if (!window.location.pathname.includes('/login')) {
        window.location.href = '/login';
      }
    }
  }

  return forward(operation);
});

const httpLink = new HttpLink({
  uri: Config.gqlUrl,
});

const link = from([authLink, httpLink, errorLink]);

// Apollo GraphQL client
const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    possibleTypes: {
      Menu: ['Page', 'MediaType'],
    },
  }),
});

// Scroll To Top on each Route Change
const ScrollToTop = () => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Index = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </BrowserRouter>
  );
};

const domNode = document.getElementById('root');

if (domNode) {
  const root = createRoot(domNode);
  root.render(<Index />);
}
