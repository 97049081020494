import Config from '../config';

/**
 * captureLinkClicks
 * @description Capturee Clicks on a-Tags to Route them to React-Router-Dom if they contain the Frontends URL
 * @param event
 * @param history
 */
const captureLinkClicks = (
  event: React.MouseEvent<HTMLElement>,
  history: any,
) => {
  let href: string | null = '';

  const eventTarget = event.target as HTMLElement;

  if (eventTarget.nodeName === 'A') {
    href = eventTarget.getAttribute('href');
  }

  if (
    eventTarget.nodeName === 'IMG' &&
    eventTarget.parentNode?.nodeName === 'A'
  ) {
    const parentNode = eventTarget.parentNode as HTMLElement;
    href = parentNode.getAttribute('href');
  }

  if (href && href.indexOf(Config.frontendUrl) > -1) {
    event.preventDefault();
    href = href.replace(Config.frontendUrl, '/');
    history.push(href);
  }

  if (href && href.indexOf(Config.backendUrl) > -1) {
    event.preventDefault();
    href = href.replace(Config.backendUrl, '/');
    history.push(href);
  }
};

export default captureLinkClicks;
