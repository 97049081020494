/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

// Constants
import { LICENSE_ACCEPTED } from '../../constants';

// Custom Hooks
import useInfiniteScroll from '../../hooks/useInfiniteScroll';

// Components
import Loader from '../Loader/Loader';
import LayoutContainer from '../LayoutContainer/LayoutContainer';
import MediaSearchBar from '../MediaSearchBar/MediaSearchBar';
import LicenseModal from '../LicenseModal/LicenseModal';
import MediaItem from '../MediaItem/MediaItem';

// Type Definitions
import {
  MediaEdgeItem,
  MediaInformation,
  MediaTypeInfo,
  PageInfo,
} from '../../types/MediaItems.types';

// Styles
import './MediaPage.scss';

const MEDIA_QUERY = gql`
  query MediaQuery(
    $id: ID!
    $first: Int
    $after: String
    $searchTerm: String!
  ) {
    mediaType(id: $id) {
      id
      description
      name
      mediaLibraryItems(
        first: $first
        after: $after
        where: { search: $searchTerm }
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          cursor
          node {
            title
            slug
            featuredImage {
              node {
                srcSet
                mediaDetails {
                  height
                  width
                }
              }
            }
            mediaLibraryFile {
              itemType
              fileSources {
                displayName
                externalUrl
                src {
                  guid
                  srcSet
                  title
                  mediaDetails {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }
    sonnenSettings {
      mediaLibraryLegal {
        legalText
      }
    }
  }
`;

const MediaPage: React.FunctionComponent = () => {
  const { mediaTypeId } = useParams<{ mediaTypeId: string }>();
  const [mediaType, setMediaType] = React.useState<MediaTypeInfo>();
  const [mediaItems, setMediaItems] = React.useState<MediaEdgeItem[]>([]);
  const [cursor, setCursor] = React.useState<string | null>(null);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [hasNextPage, setHasNextPage] = React.useState(true);
  const [showLicense, setShowLicense] = React.useState(false);
  const [licenseText, setLicenseText] = React.useState<string | null>(null);
  const [bottomBoundaryRef, { entry }] = useInfiniteScroll();

  const handleComplete = (data: MediaInformation) => {
    if (data) {
      const { id, description, name, mediaLibraryItems } = data.mediaType;
      const { edges, pageInfo } = mediaLibraryItems;
      const { legalText } = data.sonnenSettings.mediaLibraryLegal;

      if (!mediaType || id !== mediaType.id) {
        setSearchTerm('');
        setMediaType({
          id,
          description,
          name,
        });
      }

      setLicenseText(legalText);
      setMediaItems(edges);
      setHasNextPage(pageInfo.hasNextPage);
      setCursor(pageInfo.endCursor);
    }
  };

  const { loading, fetchMore, refetch } = useQuery(MEDIA_QUERY, {
    variables: { id: mediaTypeId, first: 10, after: null, searchTerm },
    onCompleted: handleComplete,
  });

  const lazyLoadMore = () => {
    if (hasNextPage) {
      fetchMore({
        variables: { after: cursor },
        updateQuery: (prev, { fetchMoreResult }: any) => {
          const {
            pageInfo,
            edges,
          }: {
            pageInfo: PageInfo;
            edges: MediaEdgeItem[];
          } = fetchMoreResult.mediaType.mediaLibraryItems;

          setHasNextPage(pageInfo.hasNextPage);
          setCursor(pageInfo.endCursor);
          setMediaItems([...mediaItems, ...edges]);
        },
      });
    }
  };

  React.useEffect(() => {
    if (entry && entry.isIntersecting && hasNextPage) lazyLoadMore();
  }, [entry]);

  React.useEffect(() => {
    if (searchTerm) {
      setCursor(null);
      refetch();
    }
  }, [searchTerm, refetch]);

  React.useEffect(() => {
    const localStorageLicenseState = localStorage.getItem(LICENSE_ACCEPTED);

    if (!localStorageLicenseState || localStorageLicenseState === 'false') {
      setShowLicense(true);
    }
  }, []);

  return (
    <LayoutContainer>
      {showLicense && licenseText && (
        <LicenseModal
          content={licenseText}
          closeModal={() => setShowLicense(false)}
        />
      )}
      <div className="media-items">
        {mediaType && (
          <div className="row">
            <div className="media-items__header col-sm-8">
              <h2>{mediaType.name}</h2>
              <p
                className="h-greyed"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: mediaType.description }}
              />
            </div>
            <div className="media-items__search col-sm-4">
              <MediaSearchBar
                title={mediaType.name}
                mediaId={mediaType.id}
                loading={loading}
                handleSubmit={(newSearchTerm: string) =>
                  setSearchTerm(newSearchTerm)
                }
              />
            </div>
          </div>
        )}
        {loading && <Loader />}
        {mediaItems && !loading && (
          <>
            <div className="row">
              {mediaItems.length > 0 ? (
                mediaItems.map((item: MediaEdgeItem) => (
                  <MediaItem item={item.node} key={item.node.slug} />
                ))
              ) : (
                <div className="media-items__no-result col-12">
                  <p>
                    No results for search term "<span>{searchTerm}</span>".
                    Please try something different.
                  </p>
                </div>
              )}
            </div>
            <div ref={bottomBoundaryRef} />
          </>
        )}
      </div>
    </LayoutContainer>
  );
};

export default MediaPage;
