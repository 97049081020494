import * as React from 'react';

import IconFacebook from '../../icons/IconFacebook';
import IconTwitter from '../../icons/IconTwitter';
import IconYoutube from '../../icons/IconYoutube';

import './Footer.scss';

const Footer: React.FunctionComponent = () => (
  <footer className="main-footer">
    <div className="main-footer__container">
      <div className="main-footer__copyright">
        <p>© sonnen GmbH - All rights reserved</p>
      </div>
      <div className="main-footer__links">
        <a
          href="https://account.sonnen.de/de/terms-of-use"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>
        <a
          href="https://sonnen.de/impressum"
          target="_blank"
          rel="noopener noreferrer"
        >
          Imprint
        </a>
        <a
          href="https://sonnen.de/datenschutz"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        <div className="main-footer__links__sm">
          <a
            href="https://twitter.com/sonnenCommunity"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconTwitter />
          </a>
          <a
            href="https://www.facebook.com/sonnende"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconFacebook />
          </a>
          <a
            href="https://www.youtube.com/user/Sonnenbatterie"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconYoutube />
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
