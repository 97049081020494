import * as React from 'react';

import './FullscreenContainer.scss';

interface IFullscreenContainer {
  children: React.ReactNode;
}

const FullscreenContainer: React.FunctionComponent<IFullscreenContainer> = (
  props: IFullscreenContainer,
) => {
  const { children } = props;
  return <div className="container-fullscreen">{children}</div>;
};

export default FullscreenContainer;
