import * as React from 'react';

import './GridContainer.scss';

interface IGridContainer {
  children: React.ReactNode;
}

const GridContainer: React.FunctionComponent<IGridContainer> = (
  props: IGridContainer,
) => {
  const { children } = props;
  return <div className="gridContainer container">{children}</div>;
};

export default GridContainer;
