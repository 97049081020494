import React from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

// Styles
import './MediaPagePublic.scss';

// Components
import Footer from '../Footer/Footer';
import FullscreenContainer from '../FullscreenContainer/FullscreenContainer';
import { MediaItem, MediaItemPublicData } from '../../types/MediaItems.types';
import MediaItemComponent from '../MediaItem/MediaItem';
import Loader from '../Loader/Loader';
import Header from '../Header/Header';

const MEDIA_QUERY = gql`
  query mediaLibraryPublicQuery($slug: String!) {
    mediaLibraryItems(where: { publicUrlSlug: $slug }) {
      edges {
        node {
          id
          title
          slug
          featuredImage {
            node {
              srcSet
              mediaDetails {
                height
                width
              }
            }
          }
          mediaLibraryFile {
            itemType
            fileSources {
              displayName
              download
              src {
                guid
                srcSet
                title
                mediaDetails {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`;

const MediaPagePublic = () => {
  const { slug } = useParams<any>();

  const [mediaItem, setMediaItem] = React.useState<MediaItem>();
  const [error, setError] = React.useState(false);

  const handleComplete = (data: MediaItemPublicData) => {
    if (data.mediaLibraryItems.edges.length === 0) {
      setError(true);
      return;
    }

    const { node: result } = data.mediaLibraryItems.edges[0];
    setMediaItem(result);
  };

  const { loading } = useQuery(MEDIA_QUERY, {
    variables: { slug },
    onCompleted: handleComplete,
  });

  return (
    <FullscreenContainer>
      <div className="mediaPagePublic">
        <Header minimal={true} />
        <div className="mediaPagePublic__container row">
          {loading && <Loader />}
          {mediaItem && (
            <>
              <h1>{mediaItem.title}</h1>
              <MediaItemComponent
                item={mediaItem}
                showHeadline={false}
                sizeClasses="col-12 col-sm-8 col-md-7 col-lg-6"
              />
            </>
          )}
          {error && <p>ERROR!</p>}
        </div>
        <Footer />
      </div>
    </FullscreenContainer>
  );
};

export default MediaPagePublic;
