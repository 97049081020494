import * as React from 'react';

// Types
import { SearchResultNode } from '../../types/Search.types';

// Misc.
import Config from '../../config';
import getExcerpt from '../../utils/getExcerpt.lib';

// Styles
import './SearchResults.scss';
import { Link } from 'react-router-dom';

interface SearchResults {
  results: SearchResultNode[];
  searchTerm: string | undefined;
}

const getBreadcrump = (link: string) => {
  const linkWithoutHost = link.replace(Config.backendUrl, '');
  const splittedString = linkWithoutHost.split('/');
  const nonEmptyStringArray = splittedString.filter(
    item => item.length !== 0 && item !== 'guidelines',
  );

  let constructedUrl = '/guideline';

  const elementArray = nonEmptyStringArray.map((item, index) => {
    const readableItem = item.replace('-', ' ');
    constructedUrl = `${constructedUrl}/${item}`;

    if (index > 0) {
      return (
        <div className="search-results__flex" key={item}>
          <span>/</span>
          <Link to={constructedUrl}>{readableItem}</Link>
        </div>
      );
    }

    return (
      <Link to={constructedUrl} key={item}>
        {readableItem}
      </Link>
    );
  });

  return elementArray;
};

const getRouterConformLink = (rawLink: string) => {
  let returnString = rawLink;
  returnString = returnString.replace(Config.backendUrl, '');
  returnString = returnString.replace('guidelines', 'guideline');
  returnString = `/${returnString}`;

  return returnString;
};

const SearchResults: React.FC<SearchResults> = (props: SearchResults) => {
  const { results, searchTerm } = props;

  /* 
    TODO: Check if searchTerm really exists in Guideline

    When searching for "image", wpgraphql gives back guidelines 
    with [image-comparison] elements, because the search happens 
    on raw content string. Make use of getExcerpts Regex and check 
    if content.search(Regex) !== -1. 
    
    the content needs to be stripped of html and vc tags first!
    avoid doing this multiple times as this might be cpu/memory intensive
  */

  return (
    <div className="search-results">
      {results &&
        results.map(result => {
          return (
            <div key={result.id} className="search-results__result">
              <Link to={getRouterConformLink(result.link)}>
                <h2>{result.title}</h2>
              </Link>
              <div className="search-results__result__excerpt">
                <p
                  dangerouslySetInnerHTML={{
                    __html: getExcerpt(result.content, searchTerm),
                  }}
                />
                <Link to={getRouterConformLink(result.link)}>read more.</Link>
              </div>
              <div className="search-results__breadcrump">
                {getBreadcrump(result.link)}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default SearchResults;
