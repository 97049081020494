import * as React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import jwtDecode from 'jwt-decode';

// Constants
import { AUTH_TOKEN, LICENSE_ACCEPTED } from '../../constants';

const GET_USER = gql`
  query getUser($userID: ID!) {
    user(id: $userID, idType: DATABASE_ID) {
      id
      username
      firstName
      lastName
      avatar {
        url
      }
    }
  }
`;

interface SSOConnectorProps {
  authFunction: (user: any) => void;
}

const SSOConnector: React.FunctionComponent<SSOConnectorProps> = ({
  authFunction,
}: SSOConnectorProps) => {
  const [userID, setUserID] = React.useState<number | null>(null);
  const { token } = useParams<any>();
  const history = useHistory();

  const [getUserData, { data, error }] = useLazyQuery(GET_USER, {
    variables: { userID },
  });

  if (error) console.error(error);

  React.useEffect(() => {
    if (token && !userID) {
      const decodedToken = jwtDecode(token);
      if (decodedToken) {
        const { id } = (decodedToken as any).data.user;
        setUserID(id);
      }
    }

    if (token && userID) {
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(LICENSE_ACCEPTED, 'false');
      getUserData();
    }
  }, [token, userID]);

  React.useEffect(() => {
    if (data) {
      const { user } = data;
      authFunction(user);
      history.push('/');
    }
  }, [data]);

  return (
    <div>
      <p>Loading SSO Connection</p>
    </div>
  );
};

export default SSOConnector;
