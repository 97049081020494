// graphql api url
const URL_DEV = 'http://localhost:8888/graphql';
const URL_PROD = 'https://brand-api.sonnen.de/graphql';

const URL_BACK_DEV = 'http://localhost:8888/';
const URL_BACK_PROD = 'https://brand-api.sonnen.de/';

const URL_FRONT_DEV = 'http://localhost:3001/';
const URL_FRONT_PROD = 'https://brand.sonnen.de/';

const Config = {
  gqlUrl: process.env.NODE_ENV === 'production' ? URL_PROD : URL_DEV,
  backendUrl:
    process.env.NODE_ENV === 'production' ? URL_BACK_PROD : URL_BACK_DEV,
  frontendUrl:
    process.env.NODE_ENV === 'production' ? URL_FRONT_PROD : URL_FRONT_DEV,
};

export default Config;
