import * as React from 'react';
import classNames from 'classnames';

// Styles
import './ImageLightbox.scss';
import IconClose from '../../icons/IconClose';

interface ImageLightbox {
  preview: string | undefined;
  lightbox: string | undefined;
  previewAlt: string | undefined;
  lightboxAlt: string | undefined;
  isPortrait: string | undefined;
}

const ImageLightbox: React.FC<ImageLightbox> = (props: ImageLightbox) => {
  const { preview, lightbox, previewAlt, lightboxAlt, isPortrait } = props;
  const [open, setOpen] = React.useState(false);

  const lightboxClasses = classNames('image-lightbox', {
    '--open': open,
    '--landscape': !isPortrait,
    '--portrait': isPortrait,
  });

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') setOpen(false);
  };

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [setOpen]);

  React.useEffect(() => {
    open
      ? document.body.classList.add('--no-scroll')
      : document.body.classList.remove('--no-scroll');
  }, [open]);

  return (
    <div className={lightboxClasses}>
      <img
        src={preview}
        className="image-lightbox__preview-image"
        alt={previewAlt}
        onClick={() => setOpen(true)}
      />
      <div className="image-lightbox__lightbox">
        <div className="image-lightbox__lightbox__inner">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="image-lightbox__close-button">
                  <button type="button" onClick={() => setOpen(false)}>
                    ESC <IconClose color="#282e44" />
                  </button>
                </div>
                <div className="row">
                  <div className="col-10 offset-1 image-lightbox__flex">
                    <img
                      src={lightbox}
                      className="image-lightbox__lightbox-image"
                      alt={lightboxAlt}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageLightbox;
