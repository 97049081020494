import * as React from 'react';

import IIcon from '../types/Icon.types';

const IconClose: React.FunctionComponent<IIcon> = ({
  size = 24,
  color = '#FFF',
}: IIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
);
export default IconClose;
