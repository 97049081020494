import * as React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

// Components
import IconSearch from '../../icons/IconSearch';

// Misc.
import useOutsideClick from '../../utils/useOutsideClick.hook';

// Style
import './SearchBar.scss';

const SearchBar: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const history = useHistory();
  const inputField = React.useRef<HTMLInputElement>(null);
  const refWrapper = React.useRef(null);

  const handleClickOutside = React.useCallback(() => {
    setOpen(false);
    setActive(false);
  }, []);

  const [setActive] = useOutsideClick(refWrapper, handleClickOutside);

  const searchClasses = classNames('search-bar', {
    '--open': open,
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    history.push(`/search/${searchTerm}`);
  };

  React.useEffect(() => {
    if (open && inputField && inputField.current) {
      inputField.current.focus();
      setActive(true);
    }
  }, [open, inputField, setActive]);

  return (
    <div className={searchClasses} ref={refWrapper}>
      <form onSubmit={handleSubmit}>
        <input
          className="search-bar__input"
          placeholder="Search..."
          type="text"
          minLength={3}
          value={searchTerm}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setSearchTerm(event.currentTarget.value)
          }
          name="search"
          id="search"
          ref={inputField}
          required
        />
      </form>
      <span className="search-bar__icon" onClick={() => setOpen(true)}>
        <IconSearch />
      </span>
    </div>
  );
};

export default SearchBar;
