import * as React from 'react';

import IIcon from '../types/Icon.types';

const IconYoutube: React.FunctionComponent<IIcon> = ({
  size = 15,
  color = '#E02F2F',
}: IIcon) => (
  <svg width={14} height={10} viewBox="0 0 14 10">
    <path
      d="M5.555 6.844L5.554 2.85l3.783 2.005-3.782 1.99zm8.305-4.687s-.137-.98-.556-1.41C12.77.18 12.174.176 11.9.143 9.94 0 7.003 0 7.003 0h-.006S4.058 0 2.099.144C1.825.177 1.23.18.696.746.276 1.178.14 2.157.14 2.157S0 3.307 0 4.458v1.08c0 1.15.14 2.3.14 2.3s.137.98.556 1.411c.533.567 1.232.549 1.544.608C3.36 9.967 7 10 7 10s2.942-.005 4.9-.148c.275-.033.871-.036 1.404-.603.42-.431.556-1.41.556-1.41S14 6.687 14 5.536V4.458c0-1.15-.14-2.3-.14-2.3z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

export default IconYoutube;
