import React from 'react';

import { MediaItem } from '../../types/MediaItems.types';
import DownloadButton from '../DownloadButton/DownloadButton';
import '../MediaItem/MediaItem.scss';

interface IMediaItemLink {
  item: MediaItem;
}

const MediaItemLink: React.FC<IMediaItemLink> = props => {
  const { item } = props;

  const previewItem = {
    src: item.featuredImage?.node.srcSet,
    title: item.title,
    width: item.featuredImage?.node.mediaDetails.width,
    height: item.featuredImage?.node.mediaDetails.height,
  };

  return (
    <div className="media-item col-12 col-sm-6 col-md-3">
      <img
        srcSet={previewItem.src}
        alt={previewItem.title}
        width={previewItem.width}
        height={previewItem.height}
      />
      <DownloadButton sources={item.mediaLibraryFile.fileSources}>
        Download
      </DownloadButton>
    </div>
  );
};

export default MediaItemLink;
