import * as React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import UserContext from '../context/UserContext';

interface IPrivateRoute {
  path: string | string[];
  exact?: boolean;
  children: React.ReactNode;
}

const PrivateRoute: React.FunctionComponent<IPrivateRoute> = (
  props: IPrivateRoute,
) => {
  const { path, exact, children, ...rest } = props;
  const location = useLocation();

  const hasLocalStorageItem = localStorage.getItem('auth-token');

  return (
    <UserContext.Consumer>
      {user => {
        return (
          <Route
            exact={exact}
            path={path}
            {...rest}
            render={() =>
              hasLocalStorageItem || user ? (
                children
              ) : (
                <Redirect
                  to={{ pathname: '/login', state: { from: location } }}
                />
              )
            }
          />
        );
      }}
    </UserContext.Consumer>
  );
};

export default PrivateRoute;
