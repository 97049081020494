import * as React from 'react';

import IIcon from '../types/Icon.types';

const IconFacebook: React.FunctionComponent<IIcon> = ({
  color = '#3B5998',
}: IIcon) => (
  <svg width={9} height={14} viewBox="0 0 9 14">
    <path
      d="M7.048 3.354H5.872c-.469 0-.566.18-.566.635v.998h1.742l-.182 1.632h-1.56v5.714H2.694V6.62H.952V4.986h1.742V3.102c0-1.443.81-2.197 2.637-2.197h1.717v2.449z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export default IconFacebook;
