import * as React from 'react';

import './ImageComparison.scss';

interface IImageComparison {
  main: string | undefined;
  comparison: string | undefined;
  width: string | undefined;
  height: string | undefined;
}

const ImageComparison: React.FunctionComponent<IImageComparison> = (
  props: IImageComparison,
) => {
  const { main, comparison, width, height } = props;
  const [transform, setTransform] = React.useState('0');

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setTransform(event.currentTarget.value);
  };

  return (
    <div className="image-comparison">
      <div className="image-comparison__container">
        <div className="image-comparison__container__main">
          <img src={main} width={width} height={height} alt="Comparison A" />
        </div>
        <div
          className="image-comparison__container__comparison"
          style={{ transform: `translateX(${transform}%)` }}
        >
          <img
            src={comparison}
            width={width}
            height={height}
            alt="Comparison B"
            style={{ transform: `translateX(-${transform}%)` }}
          />
        </div>
      </div>
      <div className="image-comparison__range-container">
        <input
          type="range"
          value={transform}
          min="0"
          max="101"
          step="0.1"
          onChange={event => handleChange(event)}
        />
      </div>
    </div>
  );
};

export default ImageComparison;
