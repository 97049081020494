import * as React from 'react';

// Constants
import { LICENSE_ACCEPTED } from '../../constants';

// Styles
import './LicenseModal.scss';

interface LicenseModal {
  closeModal: () => void;
  content: string;
}

const LicenseModal: React.FunctionComponent<LicenseModal> = ({
  closeModal,
  content,
}: LicenseModal) => {
  const agreeToLicense = () => {
    localStorage.setItem(LICENSE_ACCEPTED, 'true');
    closeModal();
  };

  return (
    <div className="license-modal">
      <div className="license-modal__inner">
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <button className="license-modal__button" onClick={agreeToLicense}>
          Agree
        </button>
      </div>
    </div>
  );
};

export default LicenseModal;
