import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { v1 as uuid } from 'uuid';

// Types
import { ISidebar, MediaLibraryMenu } from '../../types/Sidebar.types';

const SidebarMediaLibrary: React.FunctionComponent<ISidebar> = ({
  menuOpen,
  toggleMenu,
  data,
}: ISidebar) => {
  const location = useLocation();

  const handleLinkClick = () => {
    if (menuOpen) toggleMenu();
  };

  const renderMediaLibraryMenu = (data: any) => {
    const { menuItems } = data;

    return menuItems.map((node: MediaLibraryMenu) => {
      const { __typename: type } = node.connectedNode.node;

      if (node.parentId) return false;

      const { node: item } = node.connectedNode;
      const baseLink =
        type === 'Page' ? `/page/${item.slug}` : `/media/${item.id}`;

      return (
        <li key={uuid()}>
          <NavLink
            to={baseLink}
            activeClassName="active"
            onClick={handleLinkClick}
          >
            {item.name || item.title}
          </NavLink>
          {location.pathname.indexOf(baseLink) >= 0 &&
            node.childItems.nodes.length > 0 && (
              <ul className="submenu">
                {node.childItems.nodes.map((subNode) => {
                  const subNodeLink = subNode.connectedNode.node.id;

                  return (
                    <li key={uuid()}>
                      <NavLink to={`${baseLink}/media/${subNodeLink}`}>
                        {subNode.label}
                      </NavLink>
                      {location.pathname.indexOf(
                        `${baseLink}/media/${subNodeLink}`,
                      ) >= 0 && (
                        <ul className="submenu--2">
                          {subNode.childItems.nodes.map((subSubNode) => {
                            const subSubNodeLink =
                              subSubNode.connectedNode.node.id;

                            return (
                              <li key={uuid()}>
                                <NavLink
                                  to={`${baseLink}/media/${subNodeLink}/${subSubNodeLink}`}
                                >
                                  {subSubNode.label}
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
        </li>
      );
    });
  };

  return <div>{renderMediaLibraryMenu(data)}</div>;
};

export default SidebarMediaLibrary;
