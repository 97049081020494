import * as React from 'react';

// Components
import IconSearch from '../../icons/IconSearch';

// Styles
import './MediaSearchBar.scss';
import Loader from '../Loader/Loader';

interface IMediaSearchbar {
  title?: string;
  mediaId: string;
  loading: boolean;
  handleSubmit: (searchTerm: string) => void;
}

const MediaSearchBar: React.FunctionComponent<IMediaSearchbar> = ({
  title,
  mediaId,
  loading,
  handleSubmit,
}: IMediaSearchbar) => {
  const [searchTerm, setSearchTerm] = React.useState('');

  React.useEffect(() => {
    setSearchTerm('');
  }, [mediaId]);

  const passSearchTermValue = (event: React.FormEvent) => {
    event.preventDefault();
    handleSubmit(searchTerm);
  };

  return (
    <div className="media-search-bar">
      <form onSubmit={passSearchTermValue}>
        <input
          type="text"
          placeholder={`Search ${title}`}
          value={searchTerm}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setSearchTerm(event.target.value)
          }
        />
        {loading ? (
          <Loader className="--small --grey" />
        ) : (
          <div
            className="media-search-bar__search-icon"
            onClick={passSearchTermValue}
          >
            <IconSearch size={22} />
          </div>
        )}
      </form>
    </div>
  );
};

export default MediaSearchBar;
