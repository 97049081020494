import * as React from 'react';

interface ISubmenuContext {
  submenu: {
    label: string;
    sectionId: string;
  }[];
  updateSubmenu: (sections: any) => void;
}

const SubmenuContext = React.createContext<ISubmenuContext>({
  submenu: [],
  updateSubmenu: () => {},
});

export default SubmenuContext;
