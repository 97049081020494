import * as React from 'react';
import { Link } from 'react-router-dom';

// Components
import SearchBar from '../SearchBar/SearchBar';
import UserAvatar from '../UserAvatar/UserAvatar';
import SonnenLogo from '../../static/images/logo-sonnen-white-new.svg';
import IconBurger from '../../icons/IconBurger';

// Styles
import 'tippy.js/dist/tippy.css';
import './Header.scss';

interface IHeader {
  logout?: () => void;
  toggleMenu?: () => void;
  minimal?: boolean;
}

const Header: React.FunctionComponent<IHeader> = ({
  logout,
  toggleMenu,
  minimal = false,
}: IHeader) => {
  return (
    <header className="mainHeader">
      <div className="mainHeader__inner">
        <div className="mainHeader__branding">
          <Link to="/">
            <img src={SonnenLogo} alt="Sonnen | Brandportal" />
          </Link>
          <span>brand manual</span>
        </div>
        {!minimal && (
          <div className="mainHeader__navigation">
            <div className="mainHeader__search">
              <SearchBar />
            </div>
            <div className="mainHeader__avatar">
              {logout && <UserAvatar logout={logout} />}
            </div>
            <div className="mainHeader__burger" onClick={toggleMenu}>
              <IconBurger color="#fff" />
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
