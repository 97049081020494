import * as React from 'react';

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string;
}

const UserContext = React.createContext<IUser>({
  id: '',
  firstName: '',
  lastName: '',
  avatar: '',
});

export default UserContext;
