import * as React from 'react';

import './UserDropdown.scss';

interface IUserDropdown {
  logout: () => void;
}

const UserDropdown: React.FunctionComponent<IUserDropdown> = (
  props: IUserDropdown,
) => (
  <div className="userDropdown">
    <span onClick={props.logout}>Log out</span>
  </div>
);

export default UserDropdown;
