import * as React from 'react';

import IIcon from '../types/Icon.types';

const IconTwitter: React.FunctionComponent<IIcon> = ({
  size = 15,
  color = '#00ACED',
}: IIcon) => (
  <svg width={size} height={size} viewBox="0 0 13 13">
    <path
      d="M11.531 3.324c.17 3.787-2.618 8.01-7.553 8.01a7.448 7.448 0 01-4.073-1.21 5.299 5.299 0 003.934-1.115 2.668 2.668 0 01-2.484-1.87c.399.077.81.061 1.201-.046C1.278 6.832.396 5.666.424 4.418c.369.208.782.323 1.204.336C.446 3.954.11 2.37.805 1.16a7.512 7.512 0 005.478 2.813C5.896 2.29 7.157.667 8.873.667a2.64 2.64 0 011.938.85A5.26 5.26 0 0012.5.863a2.697 2.697 0 01-1.169 1.49 5.248 5.248 0 001.527-.425 5.374 5.374 0 01-1.326 1.396z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export default IconTwitter;
